/* eslint-disable react/no-danger */
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LinkWithArrow from 'src/components/linkWithArrow/LinkWithArrow';
import MarkdownRenderer from 'src/components/markdownRenderer/MarkdownRenderer';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import { getPostDate, shortenExcerpt } from 'src/helpers/post';
import OverviewData from 'src/interfaces/OverviewData';
import * as style from './OverviewHuge.module.scss';

const OverviewHuge: React.FC<Partial<OverviewData>> = ({ thumbnail, thumbnailAlt, title, date, excerpt, path }) => {
	const { locale } = useIntl();
	let thumbnailImage;
	if (thumbnail?.localFile) thumbnailImage = getImage(thumbnail.localFile);

	const NewPostBadge: React.FC<{ dateString: string }> = ({ dateString }) => {
		const now = Date.now();
		const elapsed = now - Date.parse(dateString);
		const oneDay = 86400000; // one day in miliseconds
		const daysNew = 14; // this is how long the NEW POST sign stays
		if (elapsed < daysNew * oneDay)
			return (
				<div className={style.newPost}>
					<FormattedMessage id="notification.newPost" defaultMessage="NEW POST" />
				</div>
			);
		return null;
	};

	return (
		<div className={style.container}>
			{path && (
				<Link to={getLocalizedRoute(path, locale)} className={style.image}>
					{thumbnailImage && (
						<GatsbyImage image={thumbnailImage} alt={thumbnailAlt || ''} className={style.image} />
					)}
				</Link>
			)}
			<div className={style.textContainer}>
				<NewPostBadge dateString={date || ''} />
				{path && (
					<Link className={style.gatsbyLink} to={getLocalizedRoute(path, locale)}>
						<h1 className={style.titleText}>{title}</h1>
					</Link>
				)}
				<div className={style.date}>{getPostDate(date || '', locale)}</div>

				{excerpt && (
					<div className={style.description}>
						<MarkdownRenderer>{shortenExcerpt(excerpt, 100)}</MarkdownRenderer>
					</div>
				)}
				{path && (
					<LinkWithArrow
						href={getLocalizedRoute(path, locale)}
						text={<FormattedMessage id="link.continueReading" defaultMessage="Continue reading" />}
					/>
				)}
			</div>
		</div>
	);
};

export default OverviewHuge;
