// extracted by mini-css-extract-plugin
export var button = "OverviewHuge-module--button--uAP7b";
export var container = "OverviewHuge-module--container--NmFDk";
export var date = "OverviewHuge-module--date--jf95X";
export var description = "OverviewHuge-module--description--fgNWk";
export var gatsbyLink = "OverviewHuge-module--gatsbyLink--xQ5eO";
export var icon = "OverviewHuge-module--icon--D7ceg";
export var image = "OverviewHuge-module--image--8wtHM";
export var link = "OverviewHuge-module--link--AwGCF";
export var newPost = "OverviewHuge-module--newPost--GNOPa";
export var textContainer = "OverviewHuge-module--textContainer--Gs7zL";
export var titleText = "OverviewHuge-module--titleText--Ui3F6";