import React from 'react';
import { FormattedMessage } from 'react-intl';
import cardIllustrationDesktop from 'src/assets/images/vector/card-desktop.svg';
import cardIllustration from 'src/assets/images/vector/card.svg';
import * as style from './CardAd.module.scss';

const CardAd: React.FC = () => {
	return (
		<div className={style.container}>
			<div className={style.textContainer}>
				<div className={style.text}>
					<FormattedMessage id="ad.getCard" defaultMessage="Get your SpectroCoin debit card now!" />
				</div>
				<a href="https://auth.spectrocoin.com/signup" className={style.link}>
					<div className={style.buttonContainer}>
						<FormattedMessage id="button.signUp" defaultMessage="Sign up" />
					</div>
				</a>
			</div>
			<div className={style.imageContainer}>
				<FormattedMessage id="ad.getCard.alt" defaultMessage="Get SpectroCoin prepaid card">
					{(alt: string) => (
						<>
							<img src={cardIllustration} alt={alt} className={style.image} />
							<img src={cardIllustrationDesktop} alt={alt} className={style.imageDesktop} />
						</>
					)}
				</FormattedMessage>
			</div>
		</div>
	);
};

export default CardAd;
