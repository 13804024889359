// extracted by mini-css-extract-plugin
export var button = "CardAd-module--button--HLmcz";
export var buttonContainer = "CardAd-module--buttonContainer--iJ5kB";
export var container = "CardAd-module--container--Xz41N";
export var icon = "CardAd-module--icon--RMtve";
export var image = "CardAd-module--image--kPAH-";
export var imageContainer = "CardAd-module--imageContainer--i7E1L";
export var imageDesktop = "CardAd-module--imageDesktop--W+NAY";
export var link = "CardAd-module--link--a9PG0";
export var text = "CardAd-module--text--KJbL7";
export var textContainer = "CardAd-module--textContainer--rq-l5";